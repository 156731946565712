// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Text, Paragraph, Button, Dialog } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ReactElement, useState } from 'react';
import styles from './AddDroplet.module.less';
import video from './video.png';

export type ConnectProps = {
    installationSnippet: ReactElement;
    dropletName?: string;
    resourceId?: number;
};
const AddDroplet = ({ installationSnippet, dropletName, resourceId }: ConnectProps) => {
    const translate = useTranslate('security-dashboard.Servers.AddDroplet');
    const [isShowVideo, setShowVideo] = useState(false);

    const goToButtonLink = resourceId
        ? `https://cloud.digitalocean.com/droplets/${resourceId}/terminal/ui/`
        : `https://cloud.digitalocean.com/droplets/`;
    return (
        <>
            <Dialog
                isOpen={isShowVideo}
                subtitle={translate('videoDialogTitle')}
                size="lg"
                cancelButton={false}
                onClose={() => setShowVideo(false)}
            >
                <iframe
                    width="100%"
                    height="630"
                    src="https://www.youtube.com/embed/xJ8fj88EVSg?si=dC0m4YSy2fWekhzv?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                />
            </Dialog>
            {dropletName ? (
                <Paragraph>
                    <Text intent={'muted'}>{dropletName}</Text>
                </Paragraph>
            ) : null}
            <Paragraph>
                <img
                    onClick={() => setShowVideo(true)}
                    alt=""
                    src={video}
                    className={styles.videoPreview}
                />
            </Paragraph>
            <Heading level={2}>{translate('head1')}</Heading>
            {installationSnippet}
            <Heading level={2}>{translate('head2')}</Heading>
            <Paragraph>
                <Text intent={'muted'} fontSize={'md'}>
                    {translate(
                        resourceId ? 'paragraph1singleDroplet' : 'paragraph1multipleDroplets',
                        {
                            bold: (parts) => <Text bold>{parts}</Text>,
                        },
                    )}
                </Text>
            </Paragraph>
            <Paragraph>
                <a
                    href="https://docs.wpguardian.io/#connecting-servers-to-wp-guardian"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.documentationLink}
                >
                    {translate('docsLinkText')}
                </a>
            </Paragraph>
            <Paragraph>
                <Button
                    component={'a'}
                    href={goToButtonLink}
                    intent="primary"
                    size={'lg'}
                    target="_blank"
                >
                    {translate('goToDoButton')}
                </Button>
            </Paragraph>
        </>
    );
};

export default AddDroplet;
