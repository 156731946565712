// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Drawer, Tabs, Tab, ContentLoader, StatusMessage } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AddDroplet from './AddDroplet/AddDroplet';
import banner from './banner.png';
import InstallationSnippet from '@platform360/security-dashboard/web/components/Servers/InstallationSnippet';
import useCountdown from '@platform360/libs/shared-web/helpers/useCountdown';
import useFeatureFlagEnabled from '@platform360/libs/shared-web/helpers/useFeatureFlagEnabled';
import {
    InstallationSnippetResponse,
    InstallationTokenResponse,
} from '@platform360/security-dashboard/web/api/installation';
import {
    useInstallationSnippetQuery,
    useInstallationTokenQuery,
} from '@platform360/security-dashboard/web/queries';
import { useEventListener } from 'usehooks-ts';
import {
    useResetInstallationSnippetDurationMutation,
    useResetInstallationTokenDurationMutation,
} from '@platform360/security-dashboard/web/mutations';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import useInstallationSnippet from '@platform360/security-dashboard/web/components/Servers/InstallationSnippet/useInstallationSnippet';
import AddServer from './AddServer/AddServer';

export type AddServerDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
};
const EXPIRATION_WARNING_LINE = 300;
// When the timer going red.
const EXPIRATION_BOTTOM_LINE = 180;

const ConnectDrawer = ({ isOpen, onClose }: AddServerDrawerProps) => {
    const isSnippetGenerationEnabled = useFeatureFlagEnabled(
        'securityDashboardGenerateInstallationSnippet',
    );
    const isSecurityDashboardDropletsEnabled = useFeatureFlagEnabled(
        'securityDashboardDropletsEnabled',
    );
    const navigate = useNavigate();
    const translate = useTranslate('security-dashboard.Servers.ConnectDrawer');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const documentRef = useRef<Document>(document);
    const lastExpireAtRef = useRef<null | string>(null);
    const [timer, { updateTimer, startCountdown }] = useCountdown({
        countStart: 0,
    });
    const { tabKey } = useParams<{ tabKey: string }>();
    const [searchParams] = useSearchParams();
    const [isTimerInitialized, setIsTimerInitialized] = useState(false);
    const handleTimerUpdate = useCallback(
        ({ expireAt, remainingTime }: InstallationTokenResponse | InstallationSnippetResponse) => {
            // Invalidate cache and reset timer.
            if (expireAt !== lastExpireAtRef.current) {
                updateTimer(remainingTime);
                startCountdown();
                lastExpireAtRef.current = expireAt;
                setIsTimerInitialized(true);
            }
        },
        [updateTimer, startCountdown],
    );

    const tokenData = useInstallationTokenQuery();
    const snippetData = useInstallationSnippetQuery();
    const { data, isLoading, isError, refetch } = isSnippetGenerationEnabled
        ? snippetData
        : tokenData;

    useEffect(() => {
        if (data) {
            handleTimerUpdate(data);
        }
    }, [data, handleTimerUpdate]);

    useEffect(() => {
        if (lastExpireAtRef.current && timer === 0) {
            lastExpireAtRef.current = null;
            // Hack to ensure that backend state was changed.
            setTimeout(() => {
                void refetch();
            }, 2000);
        }
    }, [refetch, timer]);

    // Synchronize timer on tab change.
    useEventListener(
        'visibilitychange',
        () => {
            if (!documentRef.current.hidden) {
                lastExpireAtRef.current = null;
                void refetch();
            }
        },
        documentRef,
    );

    const { mutate: resetTokenDuration, isPending: isResettingTokenDuration } =
        useResetInstallationTokenDurationMutation({
            onSuccess: handleTimerUpdate,
        });

    const { mutate: resetSnippetDuration, isPending: isResettingSnippetDuration } =
        useResetInstallationSnippetDurationMutation({
            onSuccess: handleTimerUpdate,
        });

    const handleResetDuration = () => {
        isSnippetGenerationEnabled ? resetSnippetDuration() : resetTokenDuration();
        analyticsEvents.wpConnectServerRefreshClick(timer);
    };

    const {
        securityDashboard: { wpAgentInstallerUrl, wpAgentRepoUrl },
    } = useConfig();

    const snippetFromToken = useInstallationSnippet(
        wpAgentInstallerUrl,
        wpAgentRepoUrl,
        tokenData.data?.token || '',
    );

    const snippet = isSnippetGenerationEnabled
        ? snippetData.data?.snippet || ''
        : snippetFromToken.snippet;

    // @todo Change resourceId and dropletName implementation in https://webpros.atlassian.net/browse/MC-8004
    const resourceId = searchParams.has('resourceId')
        ? Number(searchParams.get('resourceId'))
        : undefined;
    const dropletName = String(searchParams.get('dropletName'));

    const installationSnippet = (
        <InstallationSnippet
            isTimerHidden={!isTimerInitialized || timer > EXPIRATION_WARNING_LINE}
            timer={timer}
            timerIntent={timer <= EXPIRATION_BOTTOM_LINE ? 'danger' : 'muted'}
            snippet={snippet}
            isLoading={isLoading}
            onCopy={() => analyticsEvents.wpCopyToClipboardClick()}
            isRefreshing={
                isSnippetGenerationEnabled ? isResettingSnippetDuration : isResettingTokenDuration
            }
            onRefreshClick={handleResetDuration}
        />
    );
    useEffect(() => {
        if (isOpen) {
            analyticsEvents.wpConnectServerShown();
        } else {
            analyticsEvents.wpConnectServerClosed();
        }
    }, [analyticsEvents, isOpen]);

    const tabs = [
        {
            key: 'droplet',
            title: translate('tabs.connectDroplet'),
            children: (
                <AddDroplet
                    installationSnippet={installationSnippet}
                    resourceId={resourceId}
                    dropletName={dropletName}
                />
            ),
        },
        {
            key: 'server',
            title: translate('tabs.connectServer'),
            children: <AddServer installationSnippet={installationSnippet} />,
        },
    ];

    const activeTabIndex = tabs.findIndex(({ key }) => key === tabKey);
    const activeTab = activeTabIndex === -1 ? tabs[tabs.length - 1] : tabs[activeTabIndex];

    if (!activeTab) return null;

    const renderContent = () => {
        if (isError) {
            return (
                <StatusMessage intent="danger">
                    {translate('errors.failedToGenerateToken')}
                </StatusMessage>
            );
        }
        if (isLoading) {
            return <ContentLoader />;
        }

        return activeTab.children;
    };

    return (
        <Drawer
            title={isSecurityDashboardDropletsEnabled ? null : translate('title')}
            size="xs"
            isOpen={isOpen}
            onClose={onClose}
            form={
                tabKey === 'server'
                    ? {
                          vertical: true,
                          applyButton: false,
                          cancelButton: false,
                          submitButton: {
                              children: translate('done'),
                              onClick: onClose,
                          },
                      }
                    : undefined
            }
            sideBanner={
                <img
                    alt=""
                    src={banner}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
            }
            tabs={
                isSecurityDashboardDropletsEnabled ? (
                    <Tabs active={activeTabIndex + 1}>
                        {tabs.map(({ title, key }) => (
                            <Tab
                                key={key}
                                onClick={() => {
                                    navigate(`/security-dashboard/servers/add/${key}`);
                                }}
                                title={title}
                            />
                        ))}
                    </Tabs>
                ) : null
            }
        >
            {renderContent()}
        </Drawer>
    );
};

export default ConnectDrawer;
